.containar {
  max-width: 1080px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.w-search {
  width: 26.55%;
  padding: 0.81rem;
}
.w-search img {
  width: 1.4rem;
  height: 1.4rem;
}
.z-bg-yellow {
  background: linear-gradient(180deg, #ffb900 0%, #f19203 100%);
}
.z-bg-blue {
  background: linear-gradient(180deg, #00dbd2 0%, #05acc3 100%);
}
.text-xxs {
  font-size: 14px;
}
.width-icon-serv {
  width: 50px;
  height: 50px;
}
.z-bg-pink {
  background: linear-gradient(180deg, #ff4392 0%, #fb3464 100%);
}
.z-bg-dark-blue {
  background: linear-gradient(180deg, #21caff 0%, #0c9dee 100%);
}
.z-bg-purple {
  background: linear-gradient(180deg, #a07bef 0%, #6b6be0 100%);
}
.z-bg-silver {
  background: linear-gradient(180deg, #e5e5e5 0%, #7c7777 100%);
}
.text-uppercase {
  text-transform: uppercase;
}
.w-4-5 {
  width: 14px;
}
.h-22 {
  height: 92px;
}
.w-30 {
  width: 118px;
}
.w-70 {
  width: 83.5% !important;
}

.text-elipse {
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden;
}

.w-modal {
  width: auto;
}

.googleMaps-container {
  position: relative;
  min-width: 44rem;
  width: 100%;
  height: 60vh;
}
.modal-inner-scroll {
  overflow-y: scroll;
  max-height: 99vh;
  min-width: 44rem !important;
  min-height: 99vh;
}

.modal-inner-scroll::-webkit-scrollbar {
  width: 6px;
}
/* Track */
.modal-inner-scroll::-webkit-scrollbar-track {
  background: rgb(244, 244, 244) !important;
}

/* Handle */
.modal-inner-scroll::-webkit-scrollbar-thumb {
  background: rgb(204, 204, 204) !important;
  border-radius: 20px;
}

.icon-zonar {
  width: 192px;
  height: 192px;
}

/* footer css */

#main-footer {
  background-color: #2b2e43;
}
#footer-widgets {
  padding: 7% 0 0;
}

.footer-widget {
  float: left;
  color: #fff;
  transform: translateY(-14.5px);
}

.wp-block-group {
  box-sizing: border-box;
}
.wp-block-columns {
  display: flex;
  margin-bottom: 1.75em;
  box-sizing: border-box;
  flex-wrap: wrap !important;
  align-items: normal !important;
}
.wp-block-column {
  flex-grow: 1;
  min-width: 0;
  word-break: break-word;
  overflow-wrap: break-word;
}
.wp-container-2 > * {
  margin: 0;
}
.footer-widget .fwidget:last-child {
  margin-bottom: 0 !important;
}
.et_pb_widget {
  float: left;
  max-width: 100%;
  word-wrap: break-word;
}
#footer-widgets .footer-widget li {
  padding-left: 14px;
  position: relative;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.has-luminous-vivid-amber-color {
  color: #fcb900 !important;
}

.wp-block-image {
  margin: 0 0 1em;
}
.wp-container-6 {
  gap: 1.75em;
}
#footer-bottom {
  background-color: #2b2e43;
}
.et-social-icons {
  float: right;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
}
.et-social-icons li {
  display: inline-block;
  margin-left: 22px;
}
.et-social-icons .icon svg {
  width: 24.5px;
  height: 24.5px;
}

#footer-bottom a {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.et-social-icon a {
  display: inline-block;
  font-size: 24px;
  position: relative;
  text-align: center;
  -webkit-transition: color 300ms ease 0s;
  transition: color 300ms ease 0s;
  color: #666;
  text-decoration: none;
}

/* header css  */
.header{
  background: url("./assets/images/header.png");
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 981px) {
  .footer-widget:first-child {
    width: 57.8%;
  }
  .footer-widget:first-child {
    margin-right: 5.5%;
    margin-bottom: 5.5%;
  }

  .footer-widget:first-child .fwidget {
    margin-bottom: 9.516%;
  }

  .footer-widget:last-child {
    width: 36.7%;
  }
  .et_pb_gutters3 .footer-widget:last-child {
    margin-right: 0;
  }

  .footer-widget:nth-child(2) .fwidget {
    margin-bottom: 14.986%;
  }
}

@media (min-width: 782px) {
  .wp-block-columns {
    flex-wrap: nowrap !important;
  }
  .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
    flex-basis: 0;
    flex-grow: 1;
  }
  .wp-block-columns {
    flex-wrap: nowrap !important;
  }
  .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
    flex-basis: 0;
    flex-grow: 1;
  }
  .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column {
    flex-basis: 0;
    flex-grow: 1;
  }
}

/* Media Quries */

@media (min-width: 1024px) {
  .animate-menu {
    opacity: 1 !important;
    visibility: visible !important;
    margin-left: 0 !important;
  }
}

@media (max-width: 1220px) {
  .w-search {
    width: auto !important;
  }
  .containar {
    max-width: 80% !important;
  }
}
@media (max-width: 769px) {
  .modal-inner-scroll {
    min-width: auto !important;
  }
  .googleMaps-container {
    min-width: auto;
  }
  .w-modal {
    width: 95% !important;
  }
}

@media (max-width: 640px) {
  .w-search img {
    width: 1rem !important;
    height: 1rem !important;
  }
}

@media (max-width: 551px) {
  .googleMaps-container {
    max-height: 25rem !important;
  }
}

.toast-position{
  top: 13% !important;
}