@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Urbanist", sans-serif;
  background: #fff;
}
.myFont {
  font-family: "Segoe UI Symbol" !important;
}
